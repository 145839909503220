import React from "react";
import { graphql } from "gatsby";
import Hero from "../components/global/Hero";
import CTASection from "../components/global/CTASection";
import FlexibleContent from "../components/reuseable/FlexibleContent";
import Texture from "../components/animation/Texture";
import { Helmet } from "react-helmet";

export default function About({ data }) {
  console.log(data);
  return (
    <div>
      <Helmet>
        <title>{data.wpPage.seo.title}</title>
        <meta name="description" content={data.wpPage.seo.metaDesc} />
        <meta name="og:description" content={data.wpPage.seo.metaDesc} />
        <meta property="og:title" content={data.wpPage.seo.title} />
        <meta
          property="og:image"
          content={
            "https://morlandbathrooms.com" +
            data.wpPage.customHeroImage.heroImage.localFile.publicURL
          }
        />
      </Helmet>
      <div className="offpage-translate offpage-translate-bg"></div>
      <div className="offpage-translate">
        <Hero image={data.wpPage.customHeroImage.heroImage} title={data.wpPage.title} />
        <FlexibleContent content={data.wpPage.flexibleContentModules.flexibleContent} />
        <CTASection ctaType={"chat"} />
        <Texture
          className="texture full"
          opacity={0.05}
          position="absolute"
          bottom="0"
          height={""}
          top="0"
          watchScroll={false}
        />
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    wpPage(slug: { eq: "terms-conditions" }) {
      id
      title
      seo {
        title
        metaDesc
      }
      flexibleContentModules {
        flexibleContent {
          ... on WpPage_Flexiblecontentmodules_FlexibleContent_TextBlock {
            fieldGroupName
            textGroup {
              text
              width
            }
          }
          ... on WpPage_Flexiblecontentmodules_FlexibleContent_ImageText {
            fieldGroupName
            alignment
            text
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000, placeholder: NONE, formats: [AUTO, WEBP])
                }
              }
            }
          }
          ... on WpPage_Flexiblecontentmodules_FlexibleContent_ImageImage {
            fieldGroupName
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000, placeholder: NONE, formats: [AUTO, WEBP])
                }
              }
            }
            image2 {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000, placeholder: NONE, formats: [AUTO, WEBP])
                }
              }
            }
          }
          ... on WpPage_Flexiblecontentmodules_FlexibleContent_ImageSlider {
            fieldGroupName
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 2000, placeholder: NONE, formats: [AUTO, WEBP])
                  }
                }
              }
            }
          }
        }
      }

      customHeroImage {
        heroImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1500, placeholder: NONE, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;
